import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';

const Text = styled.div`
  ${tw`
    font-sans
    inline-block
    px-1
    md:px-2
    lg:px-3
    pb-1
    mb-2
    lg:mb-3
    leading-normal
    tracking-widest
    font-bold
    uppercase
    text-sm
    md:text-md
    lg:text-lg
    xl:text-xl
    border-0
    border-solid
    border-brand-primary
    border-b-4
  `};
`;

export default class Component extends React.Component {
  render () {
    const { label } = this.props;
    return (
      <Text>
        {label}
      </Text>
    );
  }
}

Component.propTypes = {
  label: PropTypes.string
};
Component.defaultProps = {
  label: `Label`
};
