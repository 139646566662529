import React from 'react';
import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import Screens from 'styles/Screens';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import {
  Hero
} from 'components/Text';

const easing = 500;
const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
  &:hover, &:focus {
    transition: all ${easing / 2}ms cubic-bezier(.1,.55,.4,1);
  }
`;

const SectionClients = styled.section`
  ${tw`
    bg-gray-100
    py-3
    md:py-4
    lg:py-5
  `};
`;
const Clients = styled.div`
  ${tw`
    mx-auto
    w-full
    lg:w-4/5
    pb-4
  `};
`;
const Client = styled.div`
  ${tw`
    mr--1px
    mb--1px
    p-1
    w-1/2
    xl:w-1/4
    flex
    items-stretch
    content-center
    flex-wrap
  `};
  &:hover,
  &:focus {
    img {
        ${tw`
            opacity-100
        `};
        filter: grayscale(0%);
      }
    }
  img {
    ${tw`
      max-w-full
      opacity-60
      mb-0
    `};
    filter: grayscale(100%);
    ${transition}
  }
`;
const Content = styled.div`
  ${tw`
    flex-2
    mx-auto
    text-center
    w-full
  `};
`;
const Button = styled.button`
  ${tw`
    inline-block
    bg-transparent
    border-0
    text-gray-400
    font-semibold
    hover:text-black
    tracking-wide
    px-1
    pb-0.75
    mb-0
    text-sm
    uppercase
    relative
    transition-all
    transition-700
    hover:transition-400
    transition-ease-in-out
  `};
  width: auto !important;
  &:before,
  &:after{
    ${tw`
      absolute
      block
      h-2px
      transition-all
      transition-700
      transition-ease-in-out
    `};
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    bottom: 0;
    content: '';
  };
  &:before{
    ${tw`
      bg-gray-300
      w-full
    `};
  };
  &:after{
    ${tw`
      bg-brand-secondary
      w-0
    `};
  };
  &:hover{
    &:after{
      ${tw`
        transition-400
        w-full
      `};
    }
  };
`;
const ClientList = styled.ul`
  ${tw`
    w-full
    text-center
    overflow-hidden
    transition-all
    transition-ease-out
  `};
  @media only screen and (min-width: ${Screens.md}){
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  @media only screen and (min-width: ${Screens.lg}){
    columns: 5;
    -webkit-columns: 5;
    -moz-columns: 5;
  }
  ${({ open }) => {
    return [
      !open ? tw`h-0 pt-0 lg:pb-2 opacity-0 transition-200` : tw`h-auto pt-2 lg:pt-5 lg:pb-2 opacity-100 transition-400`
    ];
  }};
`;
const ClientListItem = styled.li`
  ${tw`
    mb-1
  `};
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showList: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    const { showList } = this.state;
    console.log(`click`, showList)
    this.setState({
      showList: !showList
    })
  }

  render () {
    const { clients } = this.props;
    const { showList } = this.state;

    const clientsLogos = [];

    clients.forEach((edge) => {
      if (edge.node.sort) {
        clientsLogos.push(edge);
      }
    });

    return (
      <SectionClients>
        <Container>
          <Row>
            <Hero
              label={`Brands & Clients`}
            />
          </Row>
          {clients && (
            <Row>
              <Clients>
                <Row>
                  {clientsLogos.sort((a, b) => (a.node.sort > b.node.sort) ? 1 : ((b.node.sort > a.node.sort) ? -1 : 0)).map((edge, i) => {
                    const {
                      id,
                      name,
                      logo
                    } = edge.node;
                    return (
                      (i < 12) && (
                        logo && (
                          <Client key={id}>
                            <img src={logo.file.url} alt={name} />
                          </Client>
                        )
                      )
                    );
                  })}
                </Row>
              </Clients>
            </Row>
          )}
          {clients && (
            <Row>
              <Content>
                <Button onClick={() => this.handleClick()}>
                  View the full list
                </Button>
              </Content>
              <ClientList open={showList}>
                {clients.sort((a, b) => (a.node.name > b.node.name) ? 1 : ((b.node.name > a.node.name) ? -1 : 0)).map((edge, i) => {
                  const {
                    id,
                    name
                  } = edge.node;
                  return (
                    <ClientListItem key={`client-li-${id}`}>
                      {name}
                    </ClientListItem>
                  );
                })}
              </ClientList>
            </Row>
          )}
          {/* 
              */}
        </Container>
      </SectionClients>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
