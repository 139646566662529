import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';

const SectionContact = styled.section`
  ${tw`
    bg-white
    py-3
    md:py-4
    lg:py-8
    xl:py-10
  `};
`;
const Title = styled.h1`
  ${tw`
    flex-2
    w-full
    relative
    tracking-tight
    pb-1
    lg:pb-3
    mb-1
  `};
  &:after{
    ${tw`
      absolute
      block
      w-4
      bg-brand-primary
      h-2px
      transition-all
      transition-700
      transition-ease-in-out
    `};
    left: 0;
    bottom: 0;
    content: '';
  };
`;
const Contact = styled.address`
  ${tw`
    not-italic
    flex-2
    mb-2
    md:w-1/2
    lg:w-1/4
    text-gray-500
    lg:text-lg
  `};
  .person {
    ${tw`
      pb-1
    `};
  }
  h1 {
    ${tw`
      text-gray-800
      font-semibold
      text-lg
      lg:text-xl
      xl:text-2xl
      mb-1
      pt-2
    `};
  }
  a {
    ${tw`
      text-gray-500
      hover:text-brand-primary
      transition-all
      transition-400
      transition-ease-in-out
      relative
    `};
    &:after {
      ${tw`
        absolute
        block
        w-0
        border-0
        border-dotted
        border-gray-400
        transition-all
        transition-700
        transition-ease-in-out
      `};
      left: 0;
      bottom: 0;
      content: '';
    }
    &:hover {
      :after {
        ${tw`
          border-b
          w-full
          transition-400
        `};
      }
    }
  }
`


export default class Component extends React.Component {
  render() {
    const { hero, contacts } = this.props;
    const {
      label
    } = hero;
    const persons = [];
    const addresses = [];
    const emails = [];

    contacts.forEach((edge) => {
      const { node } = edge;
      // Persons
      if (node.name) {
        persons.push(node);
      }
      // Addresses
      else if (node.address1) {
        addresses.push(node);
      }
      // Emails
      else {
        emails.push(node);
      }
    });

    return (
      <SectionContact>
        <Container>
          <Row>
            <Title>
              {label}
            </Title>
          </Row>
          <Row>
            {persons && (
              <Contact>
                <h1>{`Business Inquires`}</h1>
                {persons.map((contact) => {
                  return (
                    <div key={contact.id} className={`person`}>
                      {contact.name && [
                        <span>{contact.name}</span>,
                        <br />
                      ]}
                      {contact.phone && [
                        <a href={`tel:+${contact.phone.replace(/[^\w\s]/gi, '')}`}>{contact.phone}</a>,
                        <br />
                      ]}
                      {contact.email && [
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      ]}
                    </div>
                  )
                })}
              </Contact>
            )}
            {emails && (
              <Contact>
                {emails.map((contact) => {
                  return (
                    <div key={contact.id}>
                      {contact.title && <h1>{contact.title}</h1>}
                      {contact.email && [
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                      ]}
                    </div>
                  )
                })}
              </Contact>
            )}
            {addresses && (
              addresses.map((contact) => {
                return (
                  <Contact key={contact.id}>
                    {contact.title && <h1>{contact.title}</h1>}
                    {contact.address1 && [
                      <span>{contact.address1}</span>,
                      <br />
                    ]}
                    {contact.aaddress2 && [
                      <span>{contact.aaddress2}</span>,
                      <br />
                    ]}
                    {contact.city && (<span>{contact.city}, </span>)}
                    {contact.state && (<span>{contact.state} </span>)}
                    {contact.zip && (<span>{contact.zip}</span>)}
                    {contact.country && [
                      <br />,
                      <span>{contact.country}</span>
                    ]}
                    {contact.phone && [
                      <br />,
                      <a href={`tel:+${contact.phone.replace(/[^\w\s]/gi, '')}`}>{contact.phone}</a>
                    ]}
                    {contact.email && [
                      <br />,
                      <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    ]}
                  </Contact>
                )
              })
            )}
          </Row>
        </Container>
      </SectionContact>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
