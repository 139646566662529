import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';

const SectionWork = styled.section`
  ${tw`
    bg-gray-800
    text-white
  `};
`;
const Col = styled.div`
  ${tw`
    lg:w-1/2
    flex-2
    mx-auto
    text-center
    w-full
    z-1
    relative
  `};
`;
const Entry = styled.div`
  ${tw`
    bg-gray-800
    hover:bg-gray-900
    border
    border-solid
    pt-aspect-ratio-16/9
    mb-0
    pb-0
    relative
    text-gray-400
    hover:text-white
    cursor-pointer
    transition-all
    transition-700
    hover:transition-300
    transition-ease-in-out
  `};
  ${(props) => {
    const { background } = props;
    return [
      background && (background.image && `background-image: url('${background.image}');`),
      background && (background.repeat ? `background-repeat: ${background.repeat};` : `background-repeat: no-repeat;`),
      background && (background.position ? `background-position: ${background.position};` : `background-position: center;`)
    ];
  }};
  background-size: 100%;
  transition: background-size 100ms ease-out !important;
  -moz-transition: background-size 100ms ease-out !important;
  -ms-transition: background-size 100ms ease-out !important;
  -o-transition: background-size 100ms ease-out !important;
  -webkit-transition: background-size 100ms ease-out !important;
  filter: grayscale(100%) brightness(0.8);
  border-color: rgba(0,0,0,0.25);
  img {
    ${tw`
      transition-all
      transition-700
      transition-ease-in-out
    `};
    filter: invert(1) saturate(0) brightness(0.75);
    -webkit-filter: invert(1) saturate(0) brightness(0.75);
    -moz-filter: invert(1) saturate(0) brightness(0.75);
    -o-filter: invert(1) saturate(0) brightness(0.75);
    -ms-filter: invert(1) saturate(0) brightness(0.75);
  }
  :hover {
    filter: grayscale(0) brightness(1);
    background-size: 102%; 
    transition: background-size 400ms ease !important;
    -moz-transition: background-size 400ms ease !important;
    -ms-transition: background-size 400ms ease !important;
    -o-transition: background-size 400ms ease !important;
    -webkit-transition: background-size 400ms ease !important;
    img {
      ${tw`
        transition-300
      `};
      filter: invert(1) saturate(0) brightness(1);
      -webkit-filter: invert(1) saturate(0) brightness(1);
      -moz-filter: invert(1) saturate(0) brightness(1);
      -o-filter: invert(1) saturate(0) brightness(1);
      -ms-filter: invert(1) saturate(0) brightness(1);
    }
  }
`;
const Content = styled.div`
  ${tw`
    absolute
    w-full
  `};
  aside {
    ${tw`
    `};
    display: none;
  }
  img {
    ${tw`
      w-1/3
    `};
  }
  transform: translate3D(-50%,-50%,0);
  top: 50%;
  left: 50%;
`;
const Button = styled.button`
  ${tw`
    lg:text-md
    bg-brand-secondary-400
    hover:bg-brand-secondary
    text-brand-secondary-700
    hover:text-brand-secondary-900
    transition-all
    transition-100
    transition-ease-in-out
    font-bold
    tracking-wide
    uppercase
    border-0
    mb-0
    py-0.5
    lg:py-1.5
    block
    w-full
    opacity-100
  `};
`;
const Link = styled.a`
  ${tw`
    text-center 
    lg:text-md
    bg-brand-primary-400
    hover:bg-brand-primary
    text-brand-primary-700
    hover:text-white
    transition-all
    transition-100
    transition-ease-in-out
    font-bold
    tracking-wide
    uppercase
    border-0
    mb-0
    py-0.5
    lg:py-1.5
    block
    w-full
  `};
`;
const Modal = styled.aside`
  ${tw`
    flex
    text-center 
    content-center
    fixed
    h-full
    w-full
    z-10
    cursor-pointer
  `};
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  ${({ open }) => {
    return [
      !open ? tw`invisible` : tw`visible`
    ];
  }};
`;
const ModalContent = styled.aside`
  ${tw`
    py-1
    lg:py-2
    mx-auto
    w-full
    px-2
    lg:px-1/12
  `};
  h3 {
    ${tw`
      mb-1
      font-light
      text-xl
      lg:text-3xl
    `};
  }
`;
const ModalClose = styled.span`
  ${tw`
    cursor-pointer
    bg-gray-800
    hover:bg-gray-700
    absolute
    block
    rounded-sm
    mt-1
    mr-1
    lg:mt-2
    lg:mr-1/48
    transition-all
    transition-200
    transition-ease
  `};
  width: 36px;
  height: 36px;
  top: 0;
  right: 0;
  &:before, &:after{
    ${tw`
      absolute
      bg-gray-600
      transition-all
      transition-200
      transition-ease
    `};
    width: 4px;
    height: 34px;
    content:'';
    top: 1px;
  }
  &:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left: 16px;
  }
  &:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right: 16px;
  }
  :hover {
    &:before, &:after{
      ${tw`
        bg-brand-secondary
      `};
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      entries: 4,
      modal: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleClick () {
    const { entries } = this.state;
    this.setState({
      entries: entries + 2
    })
  }

  handleModal (id) {
    console.log(id);
    this.setState({
      modal: id != this.state.modal ? id : null
    })
  }

  render () {
    const { portfolio } = this.props;
    const { entries } = this.state;
    return (
      <SectionWork>
        <Container fluid>
          {portfolio && (
            <Row>
              {portfolio.map((edge, i) => {
                const {
                  id,
                  client,
                  vimeoId,
                  featuredImage,
                  URL
                } = edge.node;
                const open = this.state.modal === id;
                return (
                  i < entries && [
                    <Col key={`entry-${id}`} onClick={() => this.handleModal(id)}>
                      <Entry
                        background={{
                          image: featuredImage && (featuredImage.file && featuredImage.file.url)
                        }}
                      >
                        <Content>
                          {client && [
                            client.logo && (
                              <img key={`cim-${client.id}`} src={client.logo.file.url} alt={client.name} />
                            ),
                            <aside key={`cim-${client.name}`}>
                              {client.name}
                            </aside>
                          ]}
                        </Content>
                      </Entry>
                    </Col>,
                    open && (
                      <Modal key={`modal-${id}`} open={open} key={id} onClick={() => this.handleModal(id)}>
                        <ModalContent>
                          <h3>
                            {client && client.name}
                          </h3>
                          <ReactPlayer url={URL} controls width={`100%`} height={`100%`} />
                        </ModalContent>
                        <ModalClose />
                      </Modal>
                    )
                  ]
                );
              })}
            </Row>
          )}
          {portfolio && (
            entries < portfolio.length ? (
              <Button onClick={() => this.handleClick()}>
                More Projects
              </Button>
            ) : (
                <Link href={`https://vimeo.com/eldepartamento`} target={`_blank`}>
                More on Vimeo
              </Link>
            )
          )}
        </Container>
      </SectionWork>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
