import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import {
  Hero
} from 'components/Text';

const SectionServices = styled.section`
  ${tw`
    bg-white
    py-3
    md:py-4
    lg:py-5
  `};
`;
const Service = styled.div`
  ${tw`
    flex-2
    pt-2
    md:pt-4
    lg:pt-6
    mx-0
    text-left
    text-gray-400
    w-full
    lg:w-1/3
  `};
  > div {
    ${tw`
      h-full
      border-0
      border-b
      border-solid
      border-gray-200
    `};
  }
  &:nth-child(3n+1) {
    ${tw`
      lg:pr-1/48
    `};
  }
  &:nth-child(3n+2) {
    ${tw`
      lg:px-1/48
    `};
  }
  &:nth-child(3n+3) {
    ${tw`
      lg:pl-1/48
    `};
  }
  span {
    ${tw`
      text-gray-600
      text-sm
      lg:text-md
      block
      font-bold
    `};
  }
  h1 {
    ${tw`
      text-gray-600
      text-lg
      lg:text-3xl
      mb-3
    `};
  }
  p {
    ${tw`
      lg:pr-4
    `};
  }
  ul {
    ${tw`
      mb-2
      lg:mb-3
    `};
    li {
      ${tw`
        font-light
        lg:text-xl
        mb-0
        lg:mb-0.5
      `};
    }
  }
  hr {
    background
  }

`;

export default class Component extends React.Component {
  render() {
    const { hero, services } = this.props;
    const {
      label,
      heading,
      content
    } = hero;
    return (
      <SectionServices>
        <Container>
          <Row>
            <Hero
              label={label}
              title={heading}
              content={content}
            />
          </Row>
          {services && (
            <Row>
              {services.map((edge, i) => {
                const {
                  id,
                  title,
                  summary,
                  serviceAreas
                } = edge.node;
                return (
                  <Service key={id}>
                    <div>
                      <span>0{i + 1}</span>
                      {title && <h1>{title}</h1>}
                      {summary && (
                        <MDXRenderer>
                          {summary.childMdx.body}
                        </MDXRenderer>
                      )}
                      {serviceAreas && (
                        <ul>
                          {serviceAreas.map((serviceArea) => {
                            return (
                              <li key={serviceArea.idd}>
                                {serviceArea.title}
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                  </Service>
                );
              })}
            </Row>
          )}
        </Container>
      </SectionServices>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
