// import logger from '../utils/logger';

const blinder = require('color-blind');
const Color = require('color');
const logger = require('../utils/logger');

const colorNames = [];
const colorBlind = [
  'protanomaly',
  'protanopia',
  'deuteranomaly',
  'deuteranopia',
  'tritanomaly',
  'tritanopia',
  'achromatomaly',
  'achromatopsia',
];

// Palette generator function
function palette (name, base, lightest = '#ffffff', darker = '#000000') {
  let palette = {};
  palette[`${name}`] = Color(base).hex();
  /* Generate Color shades */
  palette[`${name}-900`] = Color(base).mix(Color(darker), 0.90).hex();
  palette[`${name}-800`] = Color(base).mix(Color(darker), 0.75).hex();
  palette[`${name}-700`] = Color(base).mix(Color(darker), 0.50).hex();
  palette[`${name}-600`] = Color(base).mix(Color(darker), 0.25).hex();
  palette[`${name}-500`] = Color(base).hex();
  palette[`${name}-400`] = Color(base).mix(Color(lightest), 0.25).hex();
  palette[`${name}-300`] = Color(base).mix(Color(lightest), 0.50).hex();
  palette[`${name}-200`] = Color(base).mix(Color(lightest), 0.75).hex();
  palette[`${name}-100`] = Color(base).mix(Color(lightest), 0.90).hex();

  palette[`test-protanomaly`] = blinder.protanomaly('#6551FF'); // result: "#d1c4a0"
  /* Generate Color blind simulations */
  Object.keys(palette).forEach((color) => {
    palette[`${color}-protanomaly`] = blinder.protanomaly(palette[color]); //Part of the "Anomalous Trichromat" family of color blindness. The viewer sees low amounts of red.
    palette[`${color}-protanopia`] = blinder.protanopia(palette[color]); //Part of the "Dichromat" family of color blindness. The viewer sees no red.
    palette[`${color}-deuteranomaly`] = blinder.deuteranomaly(palette[color]); //Part of the "Anomalous Trichromat" family of color blindness. The viewer sees low amounts of green.
    palette[`${color}-deuteranopia`] = blinder.deuteranopia(palette[color]); //Part of the "Dichromat" family of color blindness. The viewer sees no green.
    palette[`${color}-tritanomaly`] = blinder.tritanomaly(palette[color]); //Part of the "Anomalous Trichromat" family of color blindness. The viewer sees low amounts of blue.
    palette[`${color}-tritanopia`] = blinder.tritanopia(palette[color]); // Part of the "Dichromat" family of color blindness. The viewer sees no blue.
    palette[`${color}-achromatomaly`] = blinder.achromatomaly(palette[color]); // Part of the "Monochromat" family of color blindness. The viewer sees the absence of most color.
    palette[`${color}-achromatopsia`] = blinder.achromatopsia(palette[color]); // Part of the "Monochromat" family of color blindness. The viewer sees no color at all.
  });
  colorNames.push(name);
  return palette;
}


let colors = {
  ...palette('brand-primary', '#8027dc', '#ffffff', '#231f20'),
  ...palette('brand-secondary', '#19ff82', '#ffffff', '#231f20'),
  ...palette('gray', '#706e80', '#ffffff', '#000000'),

  'black': '#000000',
  'white': '#ffffff', 
  'transparent': 'transparent',
}
colors.base = colors[`brand-primary`];

logger.colors();
/*
 */
  colorNames.forEach((colorName) => {
    logger.colorGroup({colorName: colorName, color: colors[colorName]});
      logger.log(
        'info',
        `%c ${colorName} \n%c 900 %c 800 %c 700 %c 600 %c 500 %c 400 %c 300 %c 200 %c 100 `,
        // `background-color: ${Color(colors[`${colorName}`])}; color: ${Color(Color(colors[`${colorName}`])).contrast(Color('black')) > 10 ? `#000` : `#fff`}; border-right: 2px solid white; ; border-bottom: 1px solid white`,
        `font-weight: bold; display: inline-block; padding: 8px 0 0; background-color: ${Color(colors[`${colorName}-500`])}; color: ${Color(Color(colors[`${colorName}-500`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-900`])}; color: ${Color(Color(colors[`${colorName}-900`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-800`])}; color: ${Color(Color(colors[`${colorName}-800`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-700`])}; color: ${Color(Color(colors[`${colorName}-700`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-600`])}; color: ${Color(Color(colors[`${colorName}-600`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-500`])}; color: ${Color(Color(colors[`${colorName}-500`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-400`])}; color: ${Color(Color(colors[`${colorName}-400`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-300`])}; color: ${Color(Color(colors[`${colorName}-300`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-200`])}; color: ${Color(Color(colors[`${colorName}-200`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
        `font-weight: bold; border-top: 28px solid ${Color(colors[`${colorName}`])}; display: inline-block; padding: 8px 0; background-color: ${Color(colors[`${colorName}-100`])}; color: ${Color(Color(colors[`${colorName}-100`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
      );
      logger.colorBlindGroup();
        colorBlind.forEach((color) => {
          const colorKey = `${colorName}-${color}`;
          const colorCode = colors[colorKey];
          const colorBase = colors[colorName];
          logger.log(
            'info',
            `%c   %c  ${color}  %c\n 900 %c 800 %c 700 %c 600 %c 500 %c 400 %c 300 %c 200 %c 100 `,
            `background-color: ${colorBase}; color: ${Color(colorBase).contrast(Color('black')) > 10 ? `#000` : `#fff`}; border-right: 2px solid white; ; border-bottom: 1px solid white`,
            `background-color: ${colorCode}; color: ${Color(colorCode).contrast(Color('black')) > 10 ? `#000` : `#fff`}; border-right: 2px solid white; ; border-bottom: 1px solid white`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-900`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-900`])}; background-color: ${Color(colors[`${colorName}-900-${color}`])}; color: ${Color(Color(colors[`${colorName}-900-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-800`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-800`])}; background-color: ${Color(colors[`${colorName}-800-${color}`])}; color: ${Color(Color(colors[`${colorName}-800-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-700`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-700`])}; background-color: ${Color(colors[`${colorName}-700-${color}`])}; color: ${Color(Color(colors[`${colorName}-700-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-600`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-600`])}; background-color: ${Color(colors[`${colorName}-600-${color}`])}; color: ${Color(Color(colors[`${colorName}-600-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-500`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-500`])}; background-color: ${Color(colors[`${colorName}-500-${color}`])}; color: ${Color(Color(colors[`${colorName}-500-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-400`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-400`])}; background-color: ${Color(colors[`${colorName}-400-${color}`])}; color: ${Color(Color(colors[`${colorName}-400-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-300`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-300`])}; background-color: ${Color(colors[`${colorName}-300-${color}`])}; color: ${Color(Color(colors[`${colorName}-300-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-200`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-200`])}; background-color: ${Color(colors[`${colorName}-200-${color}`])}; color: ${Color(Color(colors[`${colorName}-200-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
            `font-weight: bold; display: inline-block; padding: 6px 0; border-top: 12px solid ${Color(colors[`${colorName}-100`])}; border-bottom: 12px solid ${Color(colors[`${colorName}-100`])}; background-color: ${Color(colors[`${colorName}-100-${color}`])}; color: ${Color(Color(colors[`${colorName}-100-${color}`])).contrast(Color('black')) > 8 ? `#000` : `#fff`}`,
          );
        });
      logger.colorBlindGroupEnd();
    logger.colorGroupEnd();
  });

  // Object.keys(colors).forEach((color) => {
  //   logger.log(
  //     'info',
  //     `%c    ${colors[color]}    %c ${color}`,
  //     `background-color: ${colors[color]}; color: ${Color(colors[color]).contrast(Color('black')) > 10 ? `#000` : `#fff`}`,
  //     'background-color: #fff; color: #000;',
  //   );
  // });
logger.colorsEnd();

// export default colors;
module.exports = colors;
