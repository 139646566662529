import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Cookies from 'js-cookie';

import GlobalStyle from 'styles/_global';
import { CookiesConsent } from 'components/Elements';
import RhythmGrid from 'utils/RhythmGrid';
// import logger from 'utils/logger';

import { Menu } from 'components/Navigation';
import { Footer } from 'components/Sections';

const App = styled.div`
  ${tw`relative`};
  ${({ consentStyleBox, displayConsent }) => {
    return [
      displayConsent && !consentStyleBox && tw`pt-6`
    ];
  }};
`;

class Layout extends React.Component {
  render () {
    const {
      // location,
      // title,
      children
    } = this.props;
    const CONSENT = Cookies.get('CONSENT');
    const designer = process.env.GATSBY_DESIGNER_MODE === `TRUE` && true;
    const consentStyleBox = process.env.GATSBY_CONSENT_BOX === `TRUE` && true;
    const displayConsent = (!CONSENT || CONSENT) < 1 && true;
    // const rootPath = `${__PATH_PREFIX__}/`;
    return (
      <App className={`${designer && `guides`}`} displayConsent={displayConsent} consentStyleBox={consentStyleBox}>
        <GlobalStyle />
        { designer && <RhythmGrid active /> }
        <Menu colorScheme={`dark`} />
        {children}
        {displayConsent && (
          <CookiesConsent box={consentStyleBox} />
        )}
      </App>
    );
  }
}

export default Layout;
