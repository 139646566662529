import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';

import {
  Hero
} from 'components/Text';

const SectionWhoWeAre = styled.section`
  ${tw`
    bg-gray-100
    py-3
    md:py-6
    lg:py-8
    xl:py-10
    flex
    items-stretch
    content-center
    flex-wrap
  `};
`;
const Content = styled.div`
  ${tw`
    flex-2
    mx-auto
    text-center
    w-full
  `};
`;

export default class Component extends React.Component {
  render() {
    const { hero } = this.props;
    const {
      heading,
      content
    } = hero;
    return (
      <SectionWhoWeAre>
        <Container>
          <Row>
            <Content>
              <Hero
                title={heading}
                content={content}
              />
            </Content>
          </Row>
        </Container>
      </SectionWhoWeAre>
    );
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
