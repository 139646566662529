import React from 'react';
import { connect } from 'react-redux';
// import {bindActionCreators} from 'redux';

import styled, { css } from 'styled-components';
import tw from 'tailwind.macro';
import {
  // StaticQuery,
  // graphql,
  navigate,
  Link
} from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Cookies from 'js-cookie';
import Auth from 'modules/Auth0/Auth';
import logger from 'utils/logger';

import { ReactComponent as LogoText } from 'assets/EL-DEPARTAMENTO-Logo-txt.svg';
import { ReactComponent as LogoMark } from 'assets/EL-DEPARTAMENTO-Logomark.svg';
import { navigateRedux } from 'modules/Redux/actions/navigate';

const auth = new Auth();

const easing = 500;

const transition = css`
  transition: all ${easing}ms cubic-bezier(.1,.55,.4,1);
`;

const Navigation = styled.nav`
  ${tw`
    fixed
    top-0
    w-full
    text-left
    pt-1.5
    px-2
    z-20
  `};
`;
const Nav = styled.nav`
  ${tw`
    max-h-3
  `};
`;
const Logo = styled.div`
  ${tw`
    inline-block
    relative
    py-2px
    px-4px
    bg-gray-900
  `};
  width: 160px;
  height: 36px;
  svg {
    ${tw`
      fill-current	
    `};
  }
  ${(props) => {
    return [
      props.dark ? tw`text-black` : tw`text-white`,
    ];
  }};
`;
const LogoMarkContainer = styled.span`
  ${tw`
    invisible
  `};
  svg {
    ${tw`
      relative
    `};
    left: 45px;
  }
`;
const LogoTextContainer = styled.span`
  ${tw`
    inline-block
    bg-gray-900
  `};
  svg {
    ${tw`
      absolute
    `};
    height: 14px;
    top: 11px;
    left: 8px;
  }
`;

class Component extends React.Component {
  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (to) {
    navigateRedux(to);
  }

  render () {
    const CONSENT = Cookies.get('CONSENT');
    const consentStyleBox = process.env.GATSBY_CONSENT_BOX === `TRUE` && true;
    const displayConsent = !CONSENT || CONSENT < 1 && true;

    const { menu, toggleMenu, closeMenu, /*navigateRedux*/ } = this.props;
    const { isAuthenticated } = auth;
    const user = auth.getUser();

    logger.verbose(`isAuthenticated: ${isAuthenticated}`, `user`, user);

    const mainMenu = [
      {
        id: `1`,
        slug: `what-we-do`,
        label: `What We Do`
      },
      // {
      //   id: `2`,
      //   slug: `approach`,
      //   label: `Approach`
      // },
      {
        id: `3`,
        slug: `case-studies`,
        label: `Our Work`
      },
      {
        id: `4`,
        slug: `about-us`,
        label: `About Us`
      },
      {
        id: `5`,
        slug: `contact`,
        label: `Contact`
      }
    ];
    return (
      <Navigation>
        <Nav>
          <Logo>
            <LogoMarkContainer>
              <LogoMark />
            </LogoMarkContainer>
            <LogoTextContainer>
              <LogoText />
            </LogoTextContainer>
          </Logo>
        </Nav>
        {/* 
                <Link>
                  Services
                </Link>
                <Link>
                  Our Work
                </Link>
                <Link>
                  About Us
                </Link>
                <Link>
                  Contact
                </Link> */}
      </Navigation>
    );
  }
}

Component.propTypes = {
  menu: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  navigateRedux: PropTypes.func.isRequired
};

const mapStateToProps = ({ menu }) => {
  return { menu };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleMenu: () => dispatch({ type: `TOGGLE_MENU` }),
    // closeMenu: () => dispatch({ type: `CLOSE_MENU`, payload: ownProps }),
    closeMenu: () => dispatch({ type: `CLOSE_MENU` }),
    navigateRedux: () => dispatch(navigateRedux(ownProps))

  };
  // bindActionCreators({
  //   // getWantedList: getWantedList,
  //   navigateRedux: navigateRedux
  // }, dispatch);
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

class ReduxComponent extends React.Component {
  render () {
    return (
      <ConnectedComponent />
    );
  }
}

export default ReduxComponent;

// export function navigateRedux (value) {
//   return {
//     type: `CLOSE_MENU`,
//     value // it will add key `value` with argument value. 
//   };
// }

