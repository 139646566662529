import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
// import logger from 'utils/logger';

import { Container, Row } from 'components/GridSystem';
import {
  Cover
} from 'components/Sections';

const VideoBackground = styled.div`
  ${tw`
    absolute
  `}
  left: 0; right: 0;
  top: 0; bottom: 0;
  :after {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,.25);
  }
  video {
    ${tw`min-w-full min-h-full object-cover`}
  }
`;
const SectionLanding = styled.section`
  ${tw`
    bg-gray-900
    min-h-screen
    text-white
    flex
    mb-0
    top-0
    items-stretch
    content-end
    flex-wrap
    pb-4
    lg:pb-8
    xl:pb-10
    relative
    overflow-hidden
  `};
  video {
    ${tw`
      absolute
    `};
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }
  .playOverlay {
    ${tw`bg-black absolute inset-0 z-10 opacity-0`}
  }
  &:hover {
    .playOverlay {
      ${tw`opacity-10`}
    }
  }
`;
const Content = styled.div`
  ${tw`
    mx-auto
    text-center
    w-full
    z-1
    relative
    self-end
  `};
`;

const Button = styled.button`
  ${tw`
    bottom-0
    inline-block
    bg-transparent
    border-0
    text-gray-300
    hover:text-white
    font-bold
    tracking-wide
    px-1
    pb-1
    mb-0
    w-auto
    hover:w-auto
    lg:text-md
    uppercase
    relative
    transition-all
    transition-700
    hover:transition-400
    transition-ease-in-out
    z-20
  `};
  width: auto !important;
  &:before,
  &:after{
    ${tw`
      absolute
      block
      h-4px
      transition-all
      transition-700
      transition-ease-in-out
    `};
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    bottom: 0;
    content: '';
  };
  &:before{
    ${tw`
      bg-brand-primary
      w-full
    `};
  };
  &:after{
    ${tw`
      bg-brand-secondary
      w-0
    `};
  };
  &:hover{
    &:after{
      ${tw`
        transition-400
        w-full
      `};
    }
  };
`;
const Modal = styled.aside`
  ${tw`
    flex
    text-center 
    content-center
    fixed
    h-full
    w-full
    z-30
  `};
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  ${({ open }) => {
    return [
      !open ? tw`invisible` : tw`visible`
    ];
  }};
`;
const ModalContent = styled.aside`
  ${tw`
    py-1
    lg:py-2
    mx-auto
    w-full
    px-2
    lg:px-1/12
  `};
  h3 {
    ${tw`
      mb-1
      font-light
      text-xl
      lg:text-3xl
    `};
  }
`;
const ModalClose = styled.span`
  ${tw`
    cursor-pointer
    bg-gray-800
    hover:bg-gray-700
    absolute
    block
    rounded-sm
    mt-1
    mr-1
    lg:mt-2
    lg:mr-1/48
    transition-all
    transition-200
    transition-ease
  `};
  width: 36px;
  height: 36px;
  top: 0;
  right: 0;
  &:before, &:after{
    ${tw`
      absolute
      bg-gray-600
      transition-all
      transition-200
      transition-ease
    `};
    width: 4px;
    height: 34px;
    content:'';
    top: 1px;
  }
  &:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left: 16px;
  }
  &:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right: 16px;
  }
  :hover {
    &:before, &:after{
      ${tw`
        bg-brand-secondary
      `};
    }
  }
`;

export default class Component extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      modal: null
    };
    this.handleModal = this.handleModal.bind(this);
  }

  handleModal (id) {
    const { modal } = this.state;
    this.setState({
      modal: id !== modal ? id : null
    });
  }

  render () {
    const { modal } = this.state;
    const background = {
      video: `//videos.ctfassets.net/ht83b2g34cck/706xCbQOR7oZEibGaO7jNS/3ddf3f4367027834b466391a058d274f/landing-loop.mp4`
    };
    return (
      <SectionLanding onClick={() => this.handleModal(`reel`)}>
        <div className="playOverlay" />
        {background &&
          (background.video && (
            <VideoBackground>
              <video
                autoPlay
                loop
                muted
                ref={player => {
                  this.player = player
                }}
              >
                <source src={background.video} type="video/mp4" />
              </video>
            </VideoBackground>
          ))}
        <Content>
          <Button>
            Watch our reel
          </Button>
        </Content>
        <Modal open={modal} onClick={() => this.handleModal(`reel`)}>
          <ModalContent>
            <ReactPlayer
              url={`https://vimeo.com/385613134`}
              controls
              width={`100%`}
              height={`100%`}
              playing={modal ? true : false}
            />
          </ModalContent>
          <ModalClose />
        </Modal>
      </SectionLanding>
    )
  }
}
Component.propTypes = {
};
Component.defaultProps = {
};
