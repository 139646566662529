import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import Image from 'gatsby-image';

const BgImg = styled.div`
  ${tw`
    absolute
    inset-0
  `};
  }
  ${({ reverse }) => {
    return [
      reverse ? tw`flex-row-reverse` : tw`flex-row`
    ];
  }};
`;
const Mask = styled.div`
  ${tw`
    absolute
    inset-0
    bg-black
  `};
  }
  ${({ opacity }) => {
    return [
      opacity && `opacity: ${opacity}`
    ];
  }};
`;

const Component = ({
  objectFit = `cover`,
  objectPosition = `50% 50%`,
  imgStyle,
  mask,
  ...props
}) => {
  // const { imgStyle, objectFit, objectPosition } = this.props;
  const polyfillHack = `"object-fit: ${imgStyle && (imgStyle.objectFit ? imgStyle.objectFit : objectFit)}; object-position: ${imgStyle && (imgStyle.objectPosition ? imgStyle.objectPosition : objectPosition)}"`;
  return (
    <BgImg>
      <div>
        <Image
          {...props}
          style={{
            position: `absolute`,
            top: `0`,
            right: `0`,
            bottom: `0`,
            left: `0`
          }}
          imgStyle={{
            ...imgStyle,
            objectFit: imgStyle && (
              imgStyle.objectFit ? imgStyle.objectFit : objectFit
            ),
            objectPosition: imgStyle && (
              imgStyle.objectPosition ? imgStyle.objectPosition : objectPosition
            ),
            fontFamily: polyfillHack
          }}
        />
        {mask && (
          <Mask opacity={mask} />
        )}
      </div>
    </BgImg>
  );
};

export default Component;

Component.propTypes = {
  objectFit: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  imgStyle: PropTypes.object,
  mask: PropTypes.number,
  objectPosition: PropTypes.string
};
Component.defaultProps = {
  objectFit: `cover`,
  mask: 0,
  imgStyle: {},
  objectPosition: `50% 50%`
};
